const fonts = {
  //body: `'Inter var'`,
  body: "Inter UI, system-ui",
  monospace: '"Roboto Mono", Menlo, monospace',
  //heading: `'Inter Regular'`,
  //code: `'Fira Code VF'`,
  /*safe: {
    body: `system-ui, -apple-system, sans-serif`,
    heading: `system-ui, -apple-system, sans-serif`,
    code: `monospace`,
  },*/
}

export default fonts
