const links = {
  bold: {
    fontWeight: "bold",
  },
  link: {
    color: `primary`,
    textDecoration: `none`,
    textTransform: `uppercase`,
    marginX: 2,
    fontWeight: "bold",
  },
  nav: {
    color: `primary`,
    textDecoration: `none`,
    textTransform: `uppercase`,
    marginX: 2,
  },
  navlink: {
    color: "inherit",
    textDecoration: "none",
    ":hover,:focus": {
      color: "primary",
    },
  },
  navitem: {
    variant: "styles.navlink",
    display: "inline-flex",
    alignItems: "center",
    fontWeight: "bold",
    color: "primary",
    textDecoration: "none",
  },
  navitem: {
    variant: "styles.navlink",
    display: "inline-flex",
    alignItems: "center",
    fontWeight: "bold",
    color: "primary",
    textDecoration: "none",
  },
}

export default links
