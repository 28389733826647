const shadows = {
  default: `0 0 8px rgba(0, 0, 0, 0.125)`,
  active: `0 0 24px 8px rgba(0, 0, 0, 0.125)`,
  primary:
    "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
  secondary: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
  another: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
}

export default shadows
