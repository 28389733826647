//import { baseThemeSettings } from "../index"
//import unitless from "../../utils/unitless"

//const { rythm } = baseThemeSettings
const images = {
  avatar: {
    width: 48,
    height: 48,
    borderRadius: 99999,
  },
}

export default images
