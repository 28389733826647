const sizes = {
  icon: 24,
  iconButton: 40,
  lineNumber: 42,
  container: 800,
  scrollBar: 4,
  //container: 768,
  wide: 1280,
  ewide: 1600,
  auto: "auto",
  half: "50%",
  full: "100%",
  screen: "100vw",
}

export default sizes
