import { baseThemeSettings } from "../index"
import unitless from "../../utils/unitless"

const { rythm } = baseThemeSettings
const badges = {
  categories: {
    transition: `box-shadow 400ms ease`,
    //marginBottom: unitless(rythm),
    borderRadius: 0,
    //backgroundColor: `backgroundLight` || `background`,
    //backgroundColor: "secondary",
    boxShadow: `primary`,
    textDecoration: "none",
    ":hover,:focus": {
      boxShadow: `secondary`,
      transform: "translateY(-2px)",
    },
  },
  primary: {
    //padding: 3,
    marginBottom: unitless(rythm) * 2 + `rem`,
    borderRadius: 1,

    boxShadow: `primary`,
    //color: "inherit",
    textDecoration: "none",
    ":hover,:focus": {
      boxShadow: `secondary`,
    },
  },
}

export default badges
