import { baseThemeSettings } from "../index"
import unitless from "../../utils/unitless"

const { rythm } = baseThemeSettings
const cards = {
  new: {
    padding: 3,
    marginBottom: unitless(rythm) * 2 + `rem`,
    borderRadius: 1,
    boxShadow: `default`,
    transition: `box-shadow 400ms ease`,
    backgroundColor: `backgroundLight` || `background`,
    "&:hover": {
      boxShadow: `active`,
    },
  },
  primary: {
    padding: 3,
    marginBottom: unitless(rythm) * 2 + `rem`,
    borderRadius: 1,
    // boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
    boxShadow:
      "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
    //fontWeight: "bold",
    //fontSize: 3,
    color: "inherit",
    textDecoration: "none",
    ":hover,:focus": {
      // color: "primary",
      boxShadow:
        "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
      transform: "translateY(-4px)",
    },
  },
  compact: {
    padding: 1,
    borderRadius: 12,
    border: "1px solid",
    borderColor: "primary",
    boxShadow: "0 0 8px rgba(0, 0, 0, 0.125)",
    ":hover,:focus": {
      // color: "primary",
      boxShadow:
        "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
      //"opacity 0.25s cubic-bezier(0.39, 0.575, 0.565, 1)",
    },
  },
  wider: {
    //border: "1px solid ${colors.grey200}",
    padding: "3em 2.5em 2.25em 2.5em",
    borderRadius: "3px",
    textDecoration: "none",
    color: "inherit",
    display: "flex",
    flexDirection: "column",
    boxShadow: "0px 9px 24px rgba(0, 0, 0, 0.06)",
    transition: "all 150ms ease-in-out",
    ":hover,:focus": {
      color: "primary",
      boxShadow: "0px 9px 24px rgba(0, 0, 0, 0.1)",
      transition: "all 150ms ease-in-out",
    },
  },
  slight: {
    padding: 2,
    borderRadius: 4,
    boxShadow: "0 0 4px 1px rgba(0, 0, 0, 0.5)",
  },
}

export default cards
