import { baseThemeSettings } from "./index"
import unitless from "../utils/unitless"
import prism from "./prism"

const { rythm, lineHeight: baseLineHeight } = baseThemeSettings

// * root (theme-ui-specific)
// * p(paragraph)
// * a(anchor link)
// * h1(heading)
// * h2
// * h3
// * h4
// * h5
// * h6
// * img
// * pre
// * code
// * inlineCode(MDX - specific key for <code> that is not a child of <pre>)
// * ol(ordered list)
// * ul(unordered list)
// * li(list item)
// * blockquote
// * hr(horizontal rule)
// * i(italic)
// * em(emphasis)
// * b(bold)
// * strong
// * table
// * th(table header)
// * td(table data)

export default {
  root: {
    margin: 0,
    fontFamily: `body`,
    lineHeight: baseLineHeight,
    fontFeatureSettings: `'kern', 'calt', 'ss01', 'ss02', 'ss03'`,
    minHeight: `100%`,
  },
  a: {
    //color: `tomato`,
    WebkitTapHighlightColor: `transparent`,
    "&:hover": {
      //color: `orange`,
      textDecoration: `none`,
    },
  },
  p: {
    margin: `0 0 ${rythm} 0`,
    "& > img": {
      marginBottom: 0,
    },
  },
  h1: {
    variant: `text.heading`,
    fontSize: [4, 5],
    lineHeight: 5,
    color: "h1",
  },
  h2: {
    variant: `text.heading`,
    fontSize: 4,
    lineHeight: 4,
    color: "h2",
  },
  h3: {
    variant: `text.heading`,
    fontSize: 3,
    lineHeight: 3,
    color: "h3",
  },
  h4: {
    variant: `text.heading`,
    fontSize: 2,
    lineHeight: 2,
    color: "h4",
  },
  h5: {
    variant: `text.heading`,
    fontSize: 1,
    lineHeight: 1,
  },
  h6: {
    variant: `text.heading`,
    fontSize: 0,
    lineHeight: 0,
  },
  img: {
    marginBottom: rythm,
    maxWidth: `100%`,
    height: `auto`,
  },
  pre: {
    fontFamily: "monospace",
    margin: `0 0 ${rythm} 0`,
    color: `secondary`,
    bg: `muted`,
    overflowX: `auto`,
  },
  code: {
    fontFamily: "monospace",
    float: `left`,
    minWidth: `100%`,
    whiteSpace: `pre`,
    paddingY: 0,
    ...prism,
  },
  inlineCode: {
    fontFamily: "monospace",
    color: `secondary`,
    backgroundColor: `muted`,
  },
  ol: {
    margin: `0 0 ${unitless(rythm) * 2 + "rem"} 0`,
    paddingLeft: rythm,
    // listStylePosition: `outside`,
  },
  ul: {
    margin: `0 0 ${unitless(rythm) * 2 + "rem"} 0`,
    paddingLeft: rythm,
    listStyleType: `square`,
    // listStylePosition: `outside`
  },
  li: {
    "& > ul, & > ol": {
      marginBottom: 0,
    },
  },
  /*blockquote: {
    paddingLeft: rythm,
  },*/
  hr: {
    marginTop: unitless(rythm) * 3 + `rem`,
    marginBottom: unitless(rythm) * 3 + `rem`,
    border: 0,
    borderBottom: `1px solid`,
  },
  table: {
    width: `100%`,
    margin: `0 0 ${rythm} 0`,
    borderCollapse: `separate`,
    borderSpacing: 0,
  },
  th: {
    textAlign: `left`,
    verticalAlign: `bottom`,
    paddingTop: `4px`,
    paddingBottom: `4px`,
    paddingRight: `4px`,
    paddingLeft: 0,
    borderColor: `inherit`,
    borderBottomWidth: `2px`,
    borderBottomStyle: `solid`,
  },
  td: {
    textAlign: `left`,
    verticalAlign: `top`,
    paddingTop: `4px`,
    paddingBottom: `4px`,
    paddingRight: `4px`,
    paddingLeft: 0,
    borderColor: `inherit`,
    borderBottomWidth: `1px`,
    borderBottomStyle: `solid`,
  },
  blockquote: {
    fontWeight: "bold",
    fontSize: 3,
    mx: 0,
    px: 3,
    my: 5,
    borderLeft: "4px solid",
  },
  navlink: {
    color: "inherit",
    textDecoration: "none",
    ":hover,:focus": {
      color: "primary",
    },
  },
  navitem: {
    variant: "styles.navlink",
    display: "inline-flex",
    alignItems: "center",
    fontWeight: "bold",
    color: "grey",
  },
  // New Form to be tested
  forms: {
    label: {
      fontSize: 1,
      fontWeight: "bold",
      mb: 1,
    },
    input: {
      borderColor: "gray",
      borderRadius: 0,
      mb: 3,
      "&:focus": {
        borderColor: "primary",
        boxShadow: (t) => `0 0 0 2px ${t.colors.primary}`,
        outline: "none",
      },
    },
    select: {
      borderColor: "gray",
      mb: 3,
      "&:focus": {
        borderColor: "primary",
        boxShadow: (t) => `0 0 0 2px ${t.colors.primary}`,
        outline: "none",
      },
    },
    textarea: {
      borderColor: "gray",
      mb: 3,
      "&:focus": {
        borderColor: "primary",
        boxShadow: (t) => `0 0 0 2px ${t.colors.primary}`,
        outline: "none",
      },
    },
    slider: {
      bg: "muted",
    },
  },
}
