const buttons = {
  primary: {
    color: "h1",
    bg: "card",
    border: "1px solid grey",
    /*":span": {
      cursor: "pointer",
      display: "inline - block",
      position: "relative",
      transition: "0.5s",
    },*/
    ":hover,:focus": {
      bg: "primaryLight",
      boxShadow: "0px 9px 24px rgba(0, 0, 0, 0.1)",
      transition: "all 150ms ease-in-out",
      transform: "translateY(-2px)",
      /*":span": {
        content: "0644",
        position: "absolute",
        opacity: "0",
        top: "0",
        right: "-20px",
        transition: "0.5s",
      },*/
    },
  },
  secondary: {
    color: "text",
    bg: "primary",
    border: "1px solid grey",
    ":hover,:focus": {
      bg: "darkgrey",
      color: "accent",
      boxShadow: "0px 9px 24px rgba(0, 0, 0, 0.1)",
      transition: "all 150ms ease-in-out",
    },
  },
  clear: {
    color: "text",
    bg: "background",
    border: "1px solid grey",
    ":hover,:focus": {
      bg: "darkgrey",
      color: "accent",
      boxShadow: "0px 9px 24px rgba(0, 0, 0, 0.1)",
      transition: "all 150ms ease-in-out",
    },
  },
  icon: {
    height: `iconButton`,
    width: `iconButton`,
    cursor: `pointer`,
    color: `primary`,
    transition: `color 400ms ease`,
    "&:hover": {
      color: `secondary`,
    },
  },
  iconSvg: {
    height: `icon`,
    width: `icon`,
    fill: `currentColor`,
  },
}

export default buttons
