export default {
  text: "darkgrey",
  h1: "black",
  h2: "black",
  h3: "darkgrey",
  h4: "charcoal",
  background: "#fff",
  primary: "#da4219",
  primaryDark: "#a10000",
  primaryLight: "#ff7646",
  secondary: "#19b0da",
  secondaryDark: "#0080a8",
  secondaryLight: "#67e2ff",
  accent: "#ff5626",
  muted: "#ff7046",
  card: "#faf9f9",
  gray: "#555",

  gray0: "#faf9f9",
  gray1: "#f0edec",
  gray2: "#e6dfdf",
  gray3: "#dad1d0",
  gray4: "#cec1c0",
  gray5: "#c0afad",
  gray6: "#b09b99",
  gray7: "#9d8380",
  gray8: "#7d6764",
  gray9: "#493c3b",
  redbase: "#ff2b13",
  red0: "#fff8f7",
  red1: "#ffe9e6",
  red2: "#ffd8d3",
  red3: "#ffc5bf",
  red4: "#ffb0a7",
  red5: "#ff968a",
  red6: "#ff7566",
  red7: "#ff3f29",
  red8: "#d22310",
  red9: "#7e1509",

  //initialColorModeName: `light`,
  //useColorSchemeMediaQuery: true,
  //useLocalStorage: true,
  modes: {
    dark: {
      text: "#7d6764",
      h1: "white",
      h2: "white",
      h3: "darkgrey",
      h4: "lightgrey",
      background: "#130200",
      primary: "#da4219",
      primaryDark: "#a10000",
      primaryLight: "#ff7646",
      secondary: "#19b0da",
      secondaryDark: "#0080a8",
      secondaryLight: "#67e2ff",
      accent: "#ffac33",
      muted: "#ff7046",
      card: "#493c3b",
      gray: "hsl(180, 0%, 70%)",
    },
    black: {
      text: "grey",
      h1: "white",
      h2: "white",
      h3: "darkgrey",
      h4: "lightgrey",
      background: "#000",
      primary: "#da4219",
      primaryDark: "#a10000",
      primaryLight: "#ff7646",
      secondary: "#19b0da",
      secondaryDark: "#0080a8",
      secondaryLight: "#67e2ff",
      accent: "#ff5626",
      muted: "#ff7046",
      card: "#3f3f3f",
      gray: "#888",
    },
  },
}
